@import "../vars";
.first-screen{
  color: #fff;
  &--mono{
    .first-screen__inner{
      background-color: $blueGray;
    }
  }
  &--atrium{
    .first-screen__inner{
      background-color: $blue;
    }
  }
  &__inner{
    background-color: $primary;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 3px;
    overflow: hidden;
  }
  &__content{
    padding: 72px 48px;
  }
  &__title{
    max-width: 280px;
  }
  &__desc{
    margin-top: 30px;
  }
  &__img{
    img{
      width: 101%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}

.steps{
  margin: 70px 0 35px;
  &__inner{
    border-radius: 3px;
    padding: 65px 50px 65px;
    background-color: $bg;
  }
  &__title{
    max-width: 380px;
    color: #02459D;
  }
  &__items{
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 35px));
    gap: 45px 70px;
  }
  &__num{
    border-radius: 2px;
    width: 51px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    background-color: $blue;
  }
  &__subtitle{
    margin-top: 20px;
  }
  &__desc{
    margin-top: 15px;
  }
}

.system{
  padding: 35px 0;
  &__inner{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap:33px 40px;
  }
  &__title{
    max-width: 280px;
  }
  &__table{
    margin-top: 25px;
  }
  &__hint{
    margin-top: 25px;
    color: $gray;
  }
}

.gallery{
  padding: 35px 0 75px;
  &__slider{
    overflow: hidden;
    position: relative;
    .swiper{
      &-pagination {
        display: none;
      }
      &-wrapper{
        align-items: stretch;
      }
      &-slide{
        height: auto;
        border-radius: 3px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-button {
        &-prev,
        &-next {
          width: 46px;
          height: 62px;
          padding: 0;
          background-color: rgba(#02459D, .6);
          &:hover{
            background-color: rgba(#02459D, 1);
          }
        }
        &-prev {
          left: 0;
        }
        &-next {
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px){
  .first-screen{
    &__content{
      padding: 48px 32px;
    }
  }

  .system{
    &__inner{
      grid-template-columns: repeat(2, calc(50% - 10px));
      gap:33px 20px;
    }
  }
}

@media screen and (max-width: 992px){
  .first-screen{
    &__content{
      padding: 25px 20px 44px;
    }
    &__title{
      max-width: 230px;
    }
    &__desc{
      margin-top: 15px;
    }
  }

  .steps{
    margin: 30px 0 20px;
    &__inner{
      padding: 40px 20px;
    }
    &__title{
      max-width: 340px;
    }
    &__items{
      grid-template-columns: repeat(2,calc(50% - 15px));
      gap: 30px;
      margin-top: 25px;
    }
    &__num{
      width: 38px;
      height: 38px;
      font-size: 20px;
    }
    &__subtitle{
      margin-top: 15px;
    }
    &__desc{
      margin-top: 10px;
    }
  }

  .system{
    padding: 20px 0;
    &__inner{
      grid-template-columns: 100%;
    }
    &__table{
      margin-top: 15px;
    }
    &__hint{
      margin-top: 12px;
    }
  }

  .gallery{
    padding: 20px 0 45px;
    &__slider{
      .swiper{
        &-pagination {
          display: flex;
          bottom: 14px;
        }
        &-button {
          &-prev,
          &-next {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .steps{
    &__items{
      grid-template-columns: 100%;
    }
  }
}

@media screen and (max-width: 576px){
  .first-screen{
    &__inner{
      grid-template-columns: 100%;
    }
    &__img{
      order: -1;
    }
  }

  .system{
    &__title{
      max-width: 240px;
    }
  }
}

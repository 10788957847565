@import "../vars";
.catalog-card{
  background-color: $primary;
  color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  &__img{
    flex-shrink: 0;
    height: 360px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content{
    padding: 38px 32px 51px 51px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__title{
    max-width: 220px;
  }
  &__desc{
    margin-top: 18px;
  }
  &__bottom{
    margin-top: auto;
    padding-top: 16px;
    text-align: right;
  }
}

@media screen and (max-width: 992px){
  .catalog-card{
    &__img{
      height: 230px;
    }
    &__content{
      padding: 23px 23px 25px 20px;
    }
    &__title{
      max-width: 200px;
    }
    &__desc{
      margin-top: 11px;
    }
    &__bottom{
      padding-top: 11px;
    }
    &__link{
      svg{
        width: 38px;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .catalog-card{
    &__img{
      height: unset;
    }
  }
}

@import "../vars";
.mbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    &:focus {
        box-shadow: none;
    }
}

.mbtn-blue{
    height: 55px;
    width: 280px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 700;
    font-family: $font2;
    border-radius: 2px;
    color: #fff;
    background-color: $blue;
    &:hover{
        color: #fff;
        background-color: $primary;
    }
    &:active{
        color: #fff;
        background-color: $blueGray;
    }
}

@media screen and (max-width: 576px){
    .mbtn-blue{
        font-size: 15px;
    }
}

@import "../vars";
.main{
  margin-top: -195px;
  display: flex;
  overflow: hidden;
  background: $primary;
  color: #fff;
  &__slider{
    width: 50%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .swiper{
      &-container{
        height: 100%;
      }
      &-wrapper{
        align-self: stretch;
      }
      &-slide{
        height: auto;
      }
    }
  }
  &__content{
    width: 50%;
  }
  &__inner{
    margin-left: auto;
    padding: 200px 30px 90px;
  }
  &__title{
    font-size: 70px;
    font-weight: 700;
  }
  &__subtitle{
    font-size: 40px;
    line-height: 122%;
    color: #9CC0F7;
    max-width: 470px;
    margin-top: 15px;
  }
  &__desc{
    margin-top: 20px;
    font-size: 18px;
    line-height: 178%;
    font-family: $font2;
    max-width: 500px;
  }
}

.catalog{
  padding: 95px 0 30px;
  &__inner{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap: 60px 40px;
  }
  &__title{
    max-width: 380px;
    color: #02459D;
  }
  &__desc{
    margin-top: 35px;
  }
  &__items{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 40px));
    gap: 40px 80px;
  }
  &__item{
    &-title{
      font-weight: 700;
      margin-top: 10px;
    }
  }
  &__icon{
    height: 80px;
    img{
      height: 100%;
      width: auto;
      display: block;
    }
  }
  .catalog-card{
    &:nth-child(3){
      background: $blueGray;
    }
    &:nth-child(4){
      background: $blue;
    }
  }
}

.about{
  padding: 30px 0 75px;
  &__text{
    margin-top: 23px;
    column-count: 2;
    column-gap: 53px;
  }
}

.consult{
  padding: 90px 0;
  background-color: #E0EBEF;
  &__inner{
    display: grid;
    grid-template-columns: 310px calc(100% - 410px);
    gap:30px 100px;
  }
  &__title{
    color: #02459D;
    margin-bottom: 21px;
  }
}

.contacts{
  &__item{
    &:not(:last-child){
      margin-bottom: 25px;
    }
  }
  &__title{
    font-weight: 700;
  }
}

.req{
  &__form{
    display: grid;
    grid-template-columns: 1fr 1.35fr;
    gap: 40px;
  }
  &__check{
    margin-top: 16px;
    max-width: 285px;
  }
  &__btn{
    margin-top: 28px;
  }
  .fg{
    &:not(:last-child){
      margin-bottom: 25px;
    }
  }
  &__right{
    display: flex;
    flex-direction: column;
    .fg{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 0 !important;
      textarea{
        flex-grow: 1;
      }
    }
  }
}

.site{
  padding: 74px 0 88px;
  &__title{
    color: #02459D;
  }
  &__items{
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - (80px / 3)));
    gap: 20px 40px;
  }
  &__item{
    padding: 33px 38px 25px;
    border: 1px solid rgba(#BCBCBC, .8);
    display: flex;
    flex-direction: column;
  }
  &__desc{
    margin-top: 9px;
  }
  &__link{
    margin-top: auto;
    display: block;
    color: #02459D;
    padding-top: 18px;
  }
}

@media screen and (max-width: 1100px){
  .main{
    &__inner{
      padding-top: 150px;
    }
    &__title{
      font-size: 65px;
    }
    &__subtitle{
      font-size: 36px;
      max-width: 450px;
    }
  }

  .catalog{
    &__inner{
      grid-template-columns: repeat(2, calc(50% - 15px));
      gap: 60px 30px;
    }
    &__desc{
      margin-top: 25px;
    }
    &__items{
      margin-top: 20px;
      grid-template-columns: repeat(2,calc(50% - 30px));
      gap: 30px 60px;
    }
  }

  .consult{
    &__inner{
      grid-template-columns: 310px calc(100% - 350px);
      gap:30px 40px;
    }
  }

  .site{
    &__items{
      grid-template-columns: repeat(2, calc(50% - 10px));
      gap: 20px;
    }
  }
}

@media screen and (max-width: 992px){
  .main{
    display: block;
    &__content{
      width: 100%;
    }
    &__slider{
      width: 100%;
    }
    &__inner{
      width: 100% !important;
      padding: 150px 20px 36px;
    }
  }

  .catalog{
    padding: 38px 0 20px;
    &__title{
      max-width: 280px;
    }
    &__desc{
      margin-top: 15px;
    }
    &__items{
      gap: 30px 20px;
      grid-template-columns: repeat(2,calc(50% - 10px));
    }
    &__item{
      &-title{
        margin-top: 13px;
      }
    }
  }

  .about{
    padding: 20px 0 40px;
    &__text{
      margin-top: 15px;
      column-count: 1;
    }
  }

  .consult{
    padding: 36px 0 45px;
    &__inner{
      grid-template-columns: 100%;
    }
    &__title{
      margin-bottom: 15px;
    }
  }

  .contacts{
    &__item{
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }

  .req{
    &__form{
      gap: 30px;
    }
    .fg{
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    &__check{
      margin-top: 20px;
    }
    &__btn{
      margin-top: 26px;
    }
  }

  .site{
    padding: 40px 0 50px;
    &__items{
      margin-top: 15px;
    }
    &__item{
      padding: 32px 20px;
      min-height: 200px;
    }
    &__desc{
      margin-top: 15px;
    }
    &__link{
      padding-top: 15px;
    }
  }
}

@media screen and (max-width: 768px){
  .catalog{
    &__inner{
      grid-template-columns: 100%;
      gap: 30px;
    }
    &__info{
      margin-bottom: 10px;
    }
  }

  .req{
    &__form{
      grid-template-columns: 100%;
      gap: 20px;
    }
    &__btn{
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px){
  .main{
    margin-top: -110px;
    &__inner{
      padding-top: 113px;
    }
    &__title{
      font-size: 50px;
      line-height: 51px;
    }
    &__subtitle{
      font-size: 28px;
      line-height: 125%;
      max-width: 230px;
    }
    &__desc{
      font-size: 15px;
      line-height: 166%;
    }
  }

  .site{
    &__items{
      grid-template-columns: 100%;
    }
    &__subtitle{
      max-width: 205px;
    }
  }
}

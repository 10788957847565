@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import 'components/_fonts.scss';
.fg {
    position: relative;
    textarea,
    input {
        border-radius: 2px;
        border: 1px solid rgba(#184797, .3);
        padding: 0 10px;
        height: 55px;
        width: 100%;
        font-size: 16px;
        background-color: transparent;
        color: $black;
        &:focus{
            border-color: rgba(#184797, .6);
        }
    }
    textarea{
        height: 142px;
        resize: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    label{
        display: block;
        font-size: 16px;
        line-height: 162.5%;
        font-weight: 700;
        margin-bottom: 8px;
    }
}

.check {
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        cursor: pointer;
        &:checked+label {
            &::before {
                background-color: $blue;
                background-image: url(../img/check.svg);
            }
        }
    }
    label {
        padding-left: 36px;
        position: relative;
        min-height: 20px;
        font-size: 12px;
        line-height: 142%;
        cursor: pointer;
        display: block;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            border-radius: 2px;
            border: 1px solid $blue;
            background-repeat: no-repeat;
            background-position: center;
            width: 24px;
            height: 24px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all .100s;
        }
    }
    a{
        color: #02459D;
        text-decoration: underline;
    }
}

.table{
    table{
        min-width: 100%;
        border-collapse: collapse;
    }
    tr{
        &:nth-child(odd){
            background: rgba(#EFF5F7,.5);
        }
        &:nth-child(even){
            background: #EFF5F7;
        }
    }
    td{
        height: 57px;
        padding: 5px 42px;
        font-size: 16px;
        line-height: 28px;
        position: relative;
        &:not(:last-child){
            //border-right: 0.5px solid rgba(#184797, .2);
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 0.5px;
                right: 0;
                top: 0;
                background: rgba(#184797, .2);
            }
        }
        &:last-child{
            white-space: nowrap;
        }
    }
}

.swiper {
    &-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        &-bullet {
            width: 10px;
            height: 2px;
            border-radius: 100px;
            background: #fff;
            margin: 0 5px !important;
            opacity: 1;
            &-active {
                width: 14px;
                height: 4px;
                //background: $primary;
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .table{
        td{
            padding: 5px 25px;
        }
    }
}


@media screen and (max-width: 576px){
    .fg{
        input{
            height: 50px;
        }
        textarea{
            height: 95px;
        }
        label{
            font-size: 13px;
            line-height: 120%;
            //line-height: 200%;
        }
    }

    .check{
        label{
            font-size: 11px;
            line-height: 15px;
        }
    }

    .table{
        td{
            padding: 5px 20px;
            font-size: 13px;
            line-height: 21px;
            height: 55px;
        }
    }
}
